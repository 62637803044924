import { ModifiedSimulatorTroop, TroopModifiers, SimulatorTroop, SimulatorTroopWithStats, TroopBuffs } from "../types";
import * as math from "mathjs";

export const loadBattleSpecificStats = (modifiers: TroopModifiers) => (
    troop: SimulatorTroop,
    opponent: SimulatorTroop
  ): ModifiedSimulatorTroop => {
    const modifier = modifiers[troop.type][opponent.type];
    const damagePerTurn = math.evaluate(
      `(${modifier} * ${troop.attack} * ${troop.attack} / (${troop.attack} + ${opponent.defense}))`
    );
    // TODO remove default killRate
    return { ...troop, modifier, damagePerTurn, defaultKillRate: 0 };
  };

  export const calculateKills = (rounding = "down", digits = 2) => (
    attacker: SimulatorTroopWithStats | ModifiedSimulatorTroop,
    defender: SimulatorTroopWithStats | ModifiedSimulatorTroop
  ): number => {
    const rawKills = math.evaluate(
      `(${attacker.amount} * ${attacker.damagePerTurn}) / ${defender.hp}`
    );
    if(rounding === "up") return math.ceil(rawKills, digits);
    if(rounding === "norm") return math.round(rawKills, digits);
    if(rounding === "down") return math.floor(rawKills, digits);
    return math.floor(rawKills, 2);
  };
  
  export const calculateRemaining = (rounding = "down") => (start: number, kills: number): number =>{
    const result = math.evaluate(`${start} - ${kills}`);
    if(result <= 0) return 0;

    if(rounding === "up") return math.ceil(result);
    if(rounding === "norm") return math.round(result);
    if(rounding === "down") return math.floor(result);
  };

    export const emptyBuffset: TroopBuffs = {
        attack: 0,
        defense: 0,
        hp: 0
      };
      
      const calculateBuffs = (base: number, flat: number, scale: number) =>
        math.evaluate(`${base} * (1+${scale}) + ${flat}`);
      
      export const calculateBuffset = (
        base: TroopBuffs,
        flat: TroopBuffs,
        scale: TroopBuffs = emptyBuffset
      ): TroopBuffs => ({
        attack: calculateBuffs(base.attack, flat.attack, scale.attack),
        defense: calculateBuffs(base.defense, flat.defense, scale.defense),
        hp: calculateBuffs(base.hp, flat.hp, scale.hp)
      });