import "./styles.css";
import "./simulator";
import {
  reportDoubleBattle,
  reportTurnBasedBattle
} from "./simulator/reporter";
import {
  setFormFields,
  setQueryParameters,
} from "./webUtil";

// entry point
document.addEventListener('DOMContentLoaded', function () {
  document.getElementById("simulate").addEventListener("click", function (e) {
    e.preventDefault();
    simulateAndPrintResults();
  });
  const fields = setFormFields();
  if (fields) simulateAndPrintResults();
}, false);
const fields = setFormFields();
if (fields) simulateAndPrintResults();

function simulateAndPrintResults() {
  setQueryParameters();
  const attacker = {
    amount: parseInt(document.getElementById("A-amount").value, 10),
    type: document.getElementById("A-Type").value,
    tier: document.getElementById("A-Tier").value
  };
  const attackerFlatBuffs = {
    attack: parseInt(document.getElementById("ABF-A").value, 10) || 0,
    defense: parseInt(document.getElementById("ABF-D").value, 10) || 0,
    hp: parseInt(document.getElementById("ABF-H").value, 10) || 0
  };
  const attackerScaleBuffs = {
    attack: (parseFloat(document.getElementById("ABS-A").value, 10) || 0) / 100,
    defense:
      (parseFloat(document.getElementById("ABS-D").value, 10) || 0) / 100,
    hp: (parseFloat(document.getElementById("ABS-H").value, 10) || 0) / 100
  };
  const defender = {
    amount: parseInt(document.getElementById("D-amount").value, 10),
    type: document.getElementById("D-Type").value,
    tier: document.getElementById("D-Tier").value
  };
  const defenderFlatBuffs = {
    attack: parseInt(document.getElementById("DBF-A").value, 10) || 0,
    defense: parseInt(document.getElementById("DBF-D").value, 10) || 0,
    hp: parseInt(document.getElementById("DBF-H").value, 10) || 0
  };
  const defenderScaleBuffs = {
    attack: (parseFloat(document.getElementById("DBS-A").value, 10) || 0) / 100,
    defense:
      (parseFloat(document.getElementById("DBS-D").value, 10) || 0) / 100,
    hp: (parseFloat(document.getElementById("DBS-H").value, 10) || 0) / 100
  };
  console.log(attacker);
  console.log(defender);

  const selectedSimulator = document.getElementById("sim-select").value;
  const killDigits = parseInt(document.getElementById("kill-digits").value, 10);
  const killRounding = document.getElementById("kill-round-select").value;
  const remainingRounding = document.getElementById("remaining-round-select")
    .value;

  // Modifiers
  const customModifiers = {
    Siege: {
      Siege: parseFloat(document.getElementById("MSS").value) || 0.5,
      Ranged: parseFloat(document.getElementById("MSR").value) || 0.4,
      Mounted: parseFloat(document.getElementById("MSM").value) || 0.3,
      Ground: parseFloat(document.getElementById("MSG").value) || 0.347
    },
    Ranged: {
      Siege: parseFloat(document.getElementById("MRS").value) || 1.105,
      Ranged: parseFloat(document.getElementById("MRR").value) || 1,
      Mounted: parseFloat(document.getElementById("MRM").value) || 1.2,
      Ground: parseFloat(document.getElementById("MRG").value) || 0.67
    },
    Mounted: {
      Siege: parseFloat(document.getElementById("MMS").value) || 0.9333333333,
      Ranged: parseFloat(document.getElementById("MMR").value) || 0.804,
      Mounted: parseFloat(document.getElementById("MMM").value) || 1,
      Ground: parseFloat(document.getElementById("MMG").value) || 1.2
    },
    Ground: {
      Siege: parseFloat(document.getElementById("MGS").value) || 1.1,
      Ranged: parseFloat(document.getElementById("MGR").value) || 1.05,
      Mounted: parseFloat(document.getElementById("MGM").value) || 0.7,
      Ground: parseFloat(document.getElementById("MGG").value) || 1
    }
  };

  let simulator = reportDoubleBattle;

  switch (selectedSimulator) {
    case "S1":
      simulator = reportDoubleBattle;
      break;
    case "S2":
      simulator = reportTurnBasedBattle;
      break;

    default:
      simulator = reportDoubleBattle;
      break;
  }

  const [winner, summary, logs, turns] = simulator(
    attacker,
    defender,
    attackerFlatBuffs,
    attackerScaleBuffs,
    defenderFlatBuffs,
    defenderScaleBuffs,
    killRounding,
    remainingRounding,
    killDigits,
    customModifiers
  );

  document.getElementById("results").innerHTML = `
  <div id="summary">
  <h3> The winner is the ${winner} in ${turns} turns!
  </div>
 
  stats:
  <table  class="table table-striped">
   <tr>
     <th scope="col"></th>
     <th scope="col">initial troops</th>
     <th scope="col">killed</th>
     <th scope="col">survived</th>
   </tr>
   <tr>
   <td>attacker</td>
   <td>${summary.attacker.troops}</td>
   <td>${summary.attacker.killed}</td>
   <td>${summary.attacker.survived}</td>
 </tr>
 <tr>
 <td>defender</td>
 <td>${summary.defender.troops}</td>
 <td>${summary.defender.killed}</td>
 <td>${summary.defender.survived}</td>
 </tr>
  </table>
 </div>
`;
  document.getElementById("logs").innerHTML = logs;
}
