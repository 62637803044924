var formFields = [
  "A-amount",
  "A-Type",
  "A-Tier",
  "ABF-A",
  "ABF-D",
  "ABF-H",
  "ABS-A",
  "ABS-D",
  "ABS-H",
  "D-amount",
  "D-Type",
  "D-Tier",
  "DBF-A",
  "DBF-D",
  "DBF-H",
  "DBS-A",
  "DBS-D",
  "DBS-H",
  "sim-select",
  "kill-round-select",
  "remaining-round-select",
  "kill-digits",
  "MGG",
  "MGM",
  "MGR",
  "MGS",
  "MMG",
  "MMM",
  "MMR",
  "MMS",
  "MRG",
  "MRM",
  "MRR",
  "MRS",
  "MSG",
  "MSM",
  "MSR",
  "MSS"
];

function setGetParam(key, value) {
  if (window.history.pushState) {
    var params = new URLSearchParams(window.location.search);
    params.set(key, value);
    var newUrl =
      window.location.origin +
      window.location.pathname +
      "?" +
      params.toString();
    window.history.pushState({ path: newUrl }, "", newUrl);
  }
}

export function setFieldsFromParams(id) {
  const params = new URLSearchParams(window.location.search);
  const element = document.getElementById(id);
  if (document.getElementById(id)) {
    const value = params.get(id);
    if (value) {
      element.value = value;
      console.log(`setting: ${id} to ${value}`);
      return true;
    }
    console.log(`${id} param not found`);
    return false;
  }
  console.log(`${id} element not found`);
  return false;
}

export function setQueryFromFields(id) {
  const element = document.getElementById(id);
  if (document.getElementById(id)) {
    setGetParam(id, element.value);
  }
}

export function setQueryParameters() {
  for (let index = 0; index < formFields.length; index++) {
    setQueryFromFields(formFields[index]);
  }
}

export function setFormFields() {
  let match = false;
  for (let index = 0; index < formFields.length; index++) {
    const result = setFieldsFromParams(formFields[index]);
    match = match || result;
  }
  return match;
}
