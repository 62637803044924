import { TroopBuffs, TroopLookupList } from './types';

export const troopBaseBuffs: TroopLookupList<TroopBuffs> = {
  T1: {
    Ground: {
      attack: 100,
      defense: 300,
      hp: 600
    },
    Ranged: {
      attack: 130,
      defense: 100,
      hp: 250
    },
    Mounted: {
      attack: 220,
      defense: 150,
      hp: 400
    },
    Siege: {
      attack: 100,
      defense: 50,
      hp: 100
    }
  },
  T2: {
    Ground: {
      attack: 140,
      defense: 410,
      hp: 810
    },
    Ranged: {
      attack: 180,
      defense: 140,
      hp: 340
    },
    Mounted: {
      attack: 300,
      defense: 200,
      hp: 540
    },
    Siege: {
      attack: 140,
      defense: 70,
      hp: 140
    }
  },
  T3: {
    Ground: {
      attack: 190,
      defense: 550,
      hp: 1090
    },
    Ranged: {
      attack: 240,
      defense: 190,
      hp: 460
    },
    Mounted: {
      attack: 410,
      defense: 270,
      hp: 730
    },
    Siege: {
      attack: 190,
      defense: 90,
      hp: 190
    }
  },
  T4: {
    Ground: {
      attack: 260,
      defense: 740,
      hp: 1470
    },
    Ranged: {
      attack: 320,
      defense: 260,
      hp: 620
    },
    Mounted: {
      attack: 550,
      defense: 360,
      hp: 990
    },
    Siege: {
      attack: 260,
      defense: 120,
      hp: 260
    }
  },
  T5: {
    Ground: {
      attack: 350,
      defense: 1000,
      hp: 1980
    },
    Ranged: {
      attack: 430,
      defense: 350,
      hp: 840
    },
    Mounted: {
      attack: 740,
      defense: 490,
      hp: 1340
    },
    Siege: {
      attack: 350,
      defense: 160,
      hp: 350
    }
  },
  T6: {
    Ground: {
      attack: 470,
      defense: 1350,
      hp: 2670
    },
    Ranged: {
      attack: 580,
      defense: 470,
      hp: 1130
    },
    Mounted: {
      attack: 1000,
      defense: 660,
      hp: 1810
    },
    Siege: {
      attack: 470,
      defense: 220,
      hp: 470
    }
  },
  T7: {
    Ground: {
      attack: 630,
      defense: 1820,
      hp: 3600
    },
    Ranged: {
      attack: 780,
      defense: 630,
      hp: 1530
    },
    Mounted: {
      attack: 1350,
      defense: 890,
      hp: 2440
    },
    Siege: {
      attack: 630,
      defense: 300,
      hp: 630
    }
  },
  T8: {
    Ground: {
      attack: 850,
      defense: 2460,
      hp: 4860
    },
    Ranged: {
      attack: 1050,
      defense: 850,
      hp: 2070
    },
    Mounted: {
      attack: 1820,
      defense: 1200,
      hp: 3290
    },
    Siege: {
      attack: 850,
      defense: 410,
      hp: 850
    }
  },
  T9: {
    Ground: {
      attack: 1150,
      defense: 3320,
      hp: 6560
    },
    Ranged: {
      attack: 1420,
      defense: 1150,
      hp: 2790
    },
    Mounted: {
      attack: 2460,
      defense: 1620,
      hp: 4440
    },
    Siege: {
      attack: 1150,
      defense: 550,
      hp: 1150
    }
  },
  T10: {
    Ground: {
      attack: 1550,
      defense: 4480,
      hp: 8860
    },
    Ranged: {
      attack: 1920,
      defense: 1550,
      hp: 3770
    },
    Mounted: {
      attack: 3320,
      defense: 2190,
      hp: 5990
    },
    Siege: {
      attack: 1550,
      defense: 740,
      hp: 1550
    }
  },
  T11: {
    Ground: {
      attack: 1940,
      defense: 5600,
      hp: 11080
    },
    Ranged: {
      attack: 2400,
      defense: 1940,
      hp: 4720
    },
    Mounted: {
      attack: 4150,
      defense: 2740,
      hp: 7490
    },
    Siege: {
      attack: 1940,
      defense: 930,
      hp: 1940
    }
  },

  T12: {
    Ground: {
      attack: 2425,
      defense: 7000,
      hp: 13850
    },
    Ranged: {
      attack: 3000,
      defense: 2425,
      hp: 5900
    },
    Mounted: {
      attack: 5187,
      defense: 3425,
      hp: 9362
    },
    Siege: {
      attack: 2425,
      defense: 1162,
      hp: 2425
    }
  },
  T13: {
    Ground: {
      attack: 2910,
      defense: 8400,
      hp: 16620
    },
    Ranged: {
      attack: 3450,
      defense: 2780,
      hp: 6780
    },
    Mounted: {
      attack: 5800,
      defense: 3830,
      hp: 10480
    },
    Siege: {
      attack: 2780,
      defense: 1330,
      hp: 2780
    }
  },
  T14: {
    Ground: {
      attack: 3570,
      defense: 10330,
      hp: 20440
    },
    Ranged: {
      attack: 4070,
      defense: 3280,
      hp: 8000
    },
    Mounted: {
      attack: 6670,
      defense: 4400,
      hp: 12050
    },
    Siege: {
      attack: 3280,
      defense: 1560,
      hp: 3280
    }
  },
  T15: {
    Ground: {
      attack: 4230,
      defense: 11760,
      hp: 24260
    },
    Ranged: {
      attack: 4690,
      defense: 3780,
      hp: 9220
    },
    Mounted: {
      attack: 7540,
      defense: 4970,
      hp: 13620
    },
    Siege: {
      attack: 3780,
      defense: 1790,
      hp: 3780
    }
  }
};
