import {
    BattleTroopSet,
    RoundReport,
    SimulatorTroop,
    TroopIdentifier,
    TroopBuffs,
    Simulator
  } from "../types";
  import * as math from "mathjs";
  import { troopBaseBuffs } from "../troopStats";
import { Battlesummary, BootSimulator } from '../types';
import { calculateKills, calculateRemaining, loadBattleSpecificStats, calculateBuffset } from './common';
import { troopModifiers } from "../troopModifiers";
  
  const calculateRound = (killRounding = "down", remainingRounding = "down", killdigits = 2) => (
    { attackers, defenders }: BattleTroopSet,
    turn: number
  ): RoundReport => {
    const defenderKills = calculateKills(killRounding, killdigits)(defenders, attackers);
    const remainingAttackers = calculateRemaining(remainingRounding)(
      attackers.amount,
      defenderKills
    );
    const isFinish = remainingAttackers === 0;
    const attackerRevenge = isFinish
      ? 0
      : math.floor(
          calculateKills(killRounding, killdigits)({ ...attackers, amount: defenderKills }, defenders)
        );
    const attackerKills = isFinish
      ? 0
      : calculateKills()({ ...attackers, amount: remainingAttackers }, defenders);
    const remainingDefenders = calculateRemaining(remainingRounding)(
      defenders.amount,
      attackerKills + attackerRevenge
    );
    const defenderRevenge = math.floor(
      calculateKills(killRounding, killdigits)({ ...defenders, amount: attackerKills }, attackers)
    );
    const remainingAttackersAfterRevenge = remainingAttackers - defenderRevenge;
  
    return {
      turn,
      remaining: {
        attackers: { ...attackers, amount: remainingAttackersAfterRevenge },
        defenders: { ...defenders, amount: remainingDefenders }
      },
      attackerStats: {
        remainingTroops: remainingAttackersAfterRevenge,
        kills: attackerKills + attackerRevenge,
        counter: attackerRevenge
      },
      defenderStats: {
        remainingTroops: remainingDefenders,
        kills: defenderKills + defenderRevenge,
        counter: defenderRevenge
      }
    };
  };
  
  export const simulateBattle = (killRounding = "down", remainingRounding = "down", killdigits = 2): Simulator => (
    attackingTroop: SimulatorTroop,
    defendingTroop: SimulatorTroop,
    modifiers = troopModifiers
  ): [string, RoundReport[]] => {
    const attackers = loadBattleSpecificStats(modifiers)(
      attackingTroop,
      defendingTroop,
    );
    const defenders = loadBattleSpecificStats(modifiers)(
      defendingTroop,
      attackingTroop,
    );
  
    let winner = null;
    let currentState: BattleTroopSet = { attackers, defenders };
    const roundReports: RoundReport[] = [calculateRound(killRounding,remainingRounding,killdigits)(currentState, 1)];
  
    currentState = roundReports[0].remaining;
    let turn = 2;
    while (!winner) {
      if (turn >= 1000) {
        winner = "UNDECIDED";
        continue;
      }
      const report = calculateRound(killRounding,remainingRounding,killdigits)(currentState, turn);
      roundReports.push(report);
      currentState = report.remaining;
      if (report.attackerStats.remainingTroops === 0) winner = "Defender";
      if (report.defenderStats.remainingTroops === 0) winner = "Attacker";
      turn += 1;
    }
  
    return [winner, roundReports];
  };
  export const emptyBuffset: TroopBuffs = {
    attack: 0,
    defense: 0,
    hp: 0,
  };

  const formatResults = (
    at: SimulatorTroop | TroopIdentifier,
    def: SimulatorTroop | TroopIdentifier,
    reports: RoundReport[],
    winner: string,
  ): [Battlesummary, string] => {
    const lastReport = reports[reports.length - 1];
    const summary: Battlesummary = {
      attacker: {
        troops: at.amount,
        killed: def.amount - lastReport.remaining.defenders.amount,
        survived: lastReport.remaining.attackers.amount
      },
      defender: {
        troops: def.amount,
        killed: at.amount - lastReport.remaining.attackers.amount,
        survived: lastReport.remaining.defenders.amount
      }
    };
    console.table(summary);
    const logs = `
Commencing battle:
Attackers: ${JSON.stringify(at, undefined, 2)}
Defenders: ${JSON.stringify(at, undefined, 2)}

` + reports.map(t => `
turn: ${t.turn}
attackers: ${JSON.stringify(t.attackerStats, undefined, 2)}
defenders: ${JSON.stringify(t.defenderStats, undefined, 2)}
`)
      .join("") + `${winner} won.`;
    return [summary, logs];
  };

  export const bootDoubleTurnSimulator: BootSimulator =  (
    attacker: TroopIdentifier,
    defender: TroopIdentifier,
    attackerFlats = emptyBuffset,
    attackerScales = emptyBuffset,
    defenderFlats = emptyBuffset,
    defenderScales = emptyBuffset,
    killRounding = "down", remainingRounding = "down", killdigits = 2,
    modifiers = troopModifiers
  ) => {
    const attackingTroops = {
      ...attacker,
      ...calculateBuffset(troopBaseBuffs[attacker.tier][attacker.type], attackerFlats, attackerScales)
    };
    const defendingTroops = {
      ...defender,
      ...calculateBuffset(troopBaseBuffs[defender.tier][defender.type], defenderFlats, defenderScales)
    };
    const [winner, reports] = simulateBattle(killRounding, remainingRounding, killdigits)(attackingTroops, defendingTroops, modifiers);
    const [summary, logs] = formatResults(attackingTroops, defendingTroops, reports, winner);
    return [winner, summary, logs, reports.length -1];
  };