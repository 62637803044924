import * as math from "mathjs";
import {
  BattleEncounter,
  Battlesummary,
  TroopIdentifier,
  BootSimulator
} from "../types";
import { troopModifiers } from "../troopModifiers";
import { troopBaseBuffs } from "../troopStats";
import {
  calculateKills,
  calculateRemaining,
  emptyBuffset,
  calculateBuffset,
  loadBattleSpecificStats
} from "./common";

const calculateEncounter = (
  killRounding = "down",
  remainingRounding = "down",
  killdigits = 2
) => (encounter: BattleEncounter): [BattleEncounter, boolean] => {
  if (encounter.attackers.amount === 0) return [encounter, true];
  // Attack
  const totalAttackers = math.evaluate(
    `${encounter.attackers.amount} + ${encounter.gohstTroops}`
  );
  const killedDefenders = calculateKills(killRounding, killdigits)(
    { ...encounter.attackers, amount: totalAttackers },
    encounter.defenders
  );

  // Settle numbers
  const amount = calculateRemaining(remainingRounding)(
    encounter.defenders.amount,
    killedDefenders
  );

  const gohstTroops = math.evaluate(
    `${encounter.defenders.amount} - ${amount}`
  );

  const kills = math.evaluate(`${encounter.attackers.kills} + ${gohstTroops}`);

  // Construct next encounter
  return [
    {
      attackers: { ...encounter.defenders, amount },
      defenders: { ...encounter.attackers, kills },
      gohstTroops,
      turn: encounter.turn + 1
    },
    false
  ];
};

export const calculateTurns = (
  killRounding = "down",
  remainingRounding = "down",
  killdigits = 2
) => (initialEncounter: BattleEncounter): [BattleEncounter, string] => {
  let battleIsOver = false;
  let encounter = initialEncounter;
  let logs = "";

  while (!battleIsOver) {
    const [nextEncounter, isFinal] = calculateEncounter(
      killRounding,
      remainingRounding,
      killdigits
    )(encounter);
    battleIsOver = isFinal;
    logs += `
turn: ${encounter.turn}
player-${encounter.attackers.id} attacks with ${encounter.attackers.amount} ${encounter.attackers.tier} ${encounter.attackers.type}
and ${encounter.gohstTroops} gohst troops for counter attack
player-${encounter.defenders.id} defends with ${encounter.defenders.amount} ${encounter.defenders.tier} ${encounter.defenders.type};

player-${encounter.defenders.id} looses ${nextEncounter.gohstTroops} ${encounter.defenders.tier} ${encounter.defenders.type}
player-${encounter.defenders.id} remains with ${nextEncounter.attackers.amount} ${encounter.defenders.tier} ${encounter.defenders.type}
`;
    encounter = nextEncounter;
  }

  return [encounter, logs];
};

export const bootTurnBasedSimulator: BootSimulator = (
  attacker: TroopIdentifier,
  defender: TroopIdentifier,
  attackerFlats = emptyBuffset,
  attackerScales = emptyBuffset,
  defenderFlats = emptyBuffset,
  defenderScales = emptyBuffset,
  killRounding = "down",
  remainingRounding = "down",
  killdigits = 2,
  modifiers = troopModifiers
) => {
  const attackingTroop = {
    ...attacker,
    ...calculateBuffset(
      troopBaseBuffs[attacker.tier][attacker.type],
      attackerFlats,
      attackerScales
    )
  };
  const defendingTroop = {
    ...defender,
    ...calculateBuffset(
      troopBaseBuffs[defender.tier][defender.type],
      defenderFlats,
      defenderScales
    )
  };

  const attackerId = 1;
  const defenderId = 0;

  const initialEncounter: BattleEncounter = {
    attackers: {
      ...loadBattleSpecificStats(modifiers)(defendingTroop, attackingTroop),
      kills: 0,
      id: attackerId
    },
    defenders: {
      ...loadBattleSpecificStats(modifiers)(attackingTroop, defendingTroop),
      kills: 0,
      id: defenderId
    },
    gohstTroops: 0,
    turn: 0
  };
  const [result, logs] = calculateTurns(
    killRounding,
    remainingRounding,
    killdigits
  )(initialEncounter);

  const winner = result.attackers.id === attackerId ? "Attacker" : "Defender";
  const appendedlogs = logs + `
  player-${result.attackers.id} the ${winner} won.
  `;
  const summary: Battlesummary = {
    attacker: {
      troops: attackingTroop.amount,
      killed: result.attackers.kills,
      survived: result.attackers.amount
    },
    defender: {
      troops: defendingTroop.amount,
      killed: result.defenders.kills,
      survived: result.defenders.amount
    }
  };

  return [winner, summary, appendedlogs, result.turn];
};
