import { TroopType, TroopModifiers } from './types';

export const troopModifiers: TroopModifiers = {
  Siege: {
    Siege: 0.5,
    Ranged: 0.4,
    Mounted: 0.3,
    Ground: 0.347
  },
  Ranged: {
    Siege: 1.105,
    Ranged: 1,
    Mounted: 1.2,
    Ground: 0.67
  },
  Mounted: {
    Siege: 0.9333333333,
    Ranged: 0.804,
    Mounted: 1,
    Ground: 1.2
  },
  Ground: {
    Siege: 1.1,
    Ranged: 1.05,
    Mounted: 0.7,
    Ground: 1
  }
};

export const killingDefaults = (
  attackType: TroopType,
  defenseType: TroopType
): number => {
  switch (attackType) {
    case "Siege":
      switch (defenseType) {
        case "Ground":
          return 2;
        case "Mounted":
          return 3;
        case "Ranged":
          return 4;
      }
      break;
    case "Ranged":
      switch (defenseType) {
        case "Mounted":
          return 2;
      }
      break;
  }
  return 1;
};
