import { bootDoubleTurnSimulator, bootTurnBasedSimulator } from './simulators';
import { TroopIdentifier, BootSimulator, TroopBuffs, TroopModifiers } from './types';


export const reportCustomBattle = (simulator: BootSimulator): BootSimulator => (
  attacker: TroopIdentifier,
  defender: TroopIdentifier,
  attackerFlatBuffs?: TroopBuffs,
  attackerScaleBuffs?: TroopBuffs,
  defenderFlatBuffs?: TroopBuffs,
  defenderScaleBuffs?: TroopBuffs,
  killRounding = "down", remainingRounding = "down", killdigits = 2,
  modifiers?: TroopModifiers
) => {
  console.log(`commencing battle:
  attacker: ${attacker.amount} ${attacker.tier} ${attacker.type}
  defender: ${defender.amount} ${defender.tier} ${defender.type}`);
  const [winner, summary, logs, turns] = simulator(
    attacker,
    defender,
    attackerFlatBuffs,
    attackerScaleBuffs,
    defenderFlatBuffs,
    defenderScaleBuffs,
    killRounding,
    remainingRounding,
    killdigits,
    modifiers
    );
  console.log(`Winner is the: ${winner} in ${turns} turns`);
  return [winner, summary, logs, turns];
};

export const reportDoubleBattle = reportCustomBattle(bootDoubleTurnSimulator);
export const reportTurnBasedBattle = reportCustomBattle(bootTurnBasedSimulator);